.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  max-height: 160px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn-warning-light,
.bg-warning-light 
{
  background-color: #fff3cd !important;
  border-color: #fff3cd !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SecureSendHeaderText {
  /*style="font-size: 40px;font-style: italic;font-family: ui-sans-serif;"*/
  font-size: 40px;
  font-style: italic;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.SecureSendHeaderText span.bluetext {
  color: #01529c;
}
.SecureSendHeaderText span.orangetext {
  color: #f4793e;
}

.SecureSendHeaderText span.gradienttext {
  background: linear-gradient(to right, #01529c 0%, #f4793e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
