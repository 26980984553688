body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
.form-control {
  width: 75% !important;
  margin-left:auto;
  margin-right: auto;
}
*/

iframe.fileViewFrame {
  width:100%;
  min-height: 500px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.maxViewsExhausted,
.hideElem {
  display: none !important;
}

.invisibleElem {
  visibility: hidden;
}

.fileWrapper ul li {
  margin-top: 10px;
}
.fileWrapper ul li span {
  min-width: 200px;
  display: inline-block;
}

.fileWrapper ul li button {
  margin-left: 15px;
}

.FileRequestWrapper {
  text-align: left;
}

.preferredName {
  font-style: italic;
  margin: left 10px;
}

div.ssReqMsg {

}

h3, .h3 {
  /*font-size: 20px !important;*/
}

.ssPatient {
  color: darkblue;
}

table {
  width: 100%;
  max-width: 100%;
}

div.tableHeader,
div.tableFooter {
  color: white;
  font-size: 1.2em;
  font-weight: normal;
  padding: 5px;
  background: url('assets/images/header-bg.png') repeat-x scroll 50% 50% #0f558a;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

/*
div.tableHeader > div.headerNote {
  font-size: 0.9em;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 9px;

}
*/

div.headerNote {
  margin-bottom: 8px;
}

thead tr th {
  border-bottom: 1px solid LightGrey;
  border-left: 1px solid LightGrey;
  background: #c3d9ff;
  font-weight: bold;
  text-align: left;
  font-weight: bold;
}
th, td, caption {
  padding: 4px 10px 4px 5px;
}

tbody {
  border-collapse: collapse;
  border: thin solid LightGrey;

}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 4px 10px 4px 5px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
table.dataTable td, table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
tbody tr td {
  line-height: 1.6em !important;
}

tbody tr td {
  border: 1px solid LightGrey;
  /*
  border-bottom: 1px solid LightGrey;
  border-left: 1px solid LightGrey;
  */
}

.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  float: left;
}
.btn-primary {
  border: 1px solid #c5dbec !important;
  background-color: #eaf6ff !important;
  color: #333 !important;
}
.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  margin-right: 5px;
}

.currentlyViewing {
  background-color: lightgoldenrodyellow;
}

.NavDivider {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid #f4793e;
}

.PhoneInputCountry {
  margin-left: 4px;
}

.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem ;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    flex: 1 1;
    min-width: 0;
}

/* remove any lastpass form background images */
div[id^='__lpform'] {
  display: none !important;
}

input {
  background-image: none !important;
}

.form-group-header {
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}


#accessAlert {
    margin: 20px auto;
    padding-right: 6px;
    max-width: 560px;
    text-align: justify;
}

.disclaimer {
  width:60%;
  margin-left:20%;
  text-align: left;
  font-size: 12px;
}

.disclaimer input {
  margin-right: 10px;
}

.disclaimerText {
  border-bottom: 1px inset #eae7e7;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.disclaimerAck {
  text-align: center;
}

.errorHeader {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.errorMessage {
  /*margin-top:6px;*/
}